import { configureStore } from "@reduxjs/toolkit";
import { splitReducer } from "@splitsoftware/splitio-redux";
import { createWrapper } from "next-redux-wrapper";
import { agenciesSlice } from "store/reducers/agencies";
import { qboBudgetsSlice } from "store/reducers/qboBudgets";
import { manualBudgetsSlice } from "./reducers/manualBudgets";
import { clientsSlice } from "store/reducers/clients";
import { clientUsersSlice } from "store/reducers/clientUsers";
import { dataDiagnosticsSlice } from "store/reducers/dataDiagnostics";
import { hubUsersSlice } from "store/reducers/hubUsers";
import { insightsSlice } from "store/reducers/insights";
import { aiSettingsSlice } from "store/reducers/aiSettings";
import { configureExecutiveSummarySlice } from "store/reducers/reporting-template/configureExecutiveSummary";
import { configureNotesSlice } from "store/reducers/reporting-template/configureNotes";
import { reportSlice } from "store/reducers/reports";
import { sidebarSlice } from "store/reducers/sidebar";

import { aisAccountMappingSlice } from "./reducers/asiAccountMapping";

const makeStore = () => {
  return configureStore({
    reducer: {
      // navigation
      [sidebarSlice.name]: sidebarSlice.reducer,
      // /reporting-template
      [configureNotesSlice.name]: configureNotesSlice.reducer,
      [configureExecutiveSummarySlice.name]:
        configureExecutiveSummarySlice.reducer,
      // /ai-settings
      [aiSettingsSlice.name]: aiSettingsSlice.reducer,
      // /data-diagnostics
      [dataDiagnosticsSlice.name]: dataDiagnosticsSlice.reducer,
      // /hub-users
      [hubUsersSlice.name]: hubUsersSlice.reducer,
      // /client-users
      [clientUsersSlice.name]: clientUsersSlice.reducer,
      // /agencies
      [agenciesSlice.name]: agenciesSlice.reducer,
      // /clients
      [clientsSlice.name]: clientsSlice.reducer,
      // /qbo-budgets
      [qboBudgetsSlice.name]: qboBudgetsSlice.reducer,
      // /manual-budgets
      [manualBudgetsSlice.name]: manualBudgetsSlice.reducer,
      // /insights
      [insightsSlice.name]: insightsSlice.reducer,
      // /asi-account-mapping
      [aisAccountMappingSlice.name]: aisAccountMappingSlice.reducer,
      // reports
      [reportSlice.name]: reportSlice.reducer,
      // 3rd party integrations
      splitio: splitReducer,
    },
    devTools: true,
  });
};

type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];
export const wrapper = createWrapper<AppStore>(makeStore);
