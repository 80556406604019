import { notify } from "@components/notify";
import { useClient } from "@context/client";
import { ReportModuleData, TemplateModuleData } from "@context/report/types";
import { useClientTemplatePackages } from "@hooks/apis/useClientTemplatePackages";
import { useFinancialPackageDetails } from "@hooks/apis/useFinancialPackageDetails";
import ReportServices from "@services/APIs/Reports";
import LocalServices from "@services/LocalServices";
import { reportPublishStatus } from "@utils/constant/reports";
import { reportComponentItemResponseHelper } from "@utils/helper/reports/coverPage";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { ReportContextType } from "./context-type";

const ReportContext = createContext<ReportContextType | undefined>(undefined);

export const useReport = () => useContext(ReportContext);

interface Props {
  children: React.ReactNode;
}

const ReportProvider = ({ children }: Props) => {
  const route = useRouter();

  const [reportTemplateId, setReportTemplateId] = useState<string | null>(null);
  const [packageComponentId, setPackageComponentId] = useState<string | null>(
    null
  );
  const [financialPackageId, setFinancialPackageId] = useState<string | null>(
    null
  );

  const { t } = useTranslation();
  const { clientId } = useClient();
  const { financialPackage } = useFinancialPackageDetails(
    clientId,
    financialPackageId
  );

  const { packagesData, refetch: refetchPackages } = useClientTemplatePackages(
    clientId,
    reportTemplateId,
    !!financialPackageId,
    financialPackage?.year,
    financialPackage?.month
  );
  const [isShowFullReport, setIsShowFullReport] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [callSave, setCallSave] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [notesPages, setNotesPages] = useState(1);
  const [disableButtons, setDisableButtons] = useState(false);
  const [lastClientId, setLastClientId] = useState<string | null>(null);

  useEffect(() => {
    if (!clientId) {
      return;
    }

    if (!lastClientId) {
      setLastClientId(clientId);
      return;
    }

    if (lastClientId !== clientId) {
      clearContext();
      setLastClientId(clientId);
      setReportTemplateId(null);
      setPackageComponentId(null);
      setFinancialPackageId(null);
      clearContext();
    }
  }, [clientId]);

  useEffect(() => {
    if (refetch) {
      setTimeout(() => {
        setRefetch(false);
      }, 5000);
    }
  }, [refetch]);

  useEffect(() => {
    if (callSave) {
      setTimeout(() => {
        let startedCallResponse =
          LocalServices.setFullReportAPICallsStarted("get");
        if (startedCallResponse?.length <= 0) {
          setCallSave(false);
        }
      }, 8000);
    }
  }, [callSave]);

  useEffect(() => {
    const confirmationMessage = "Changes you made may not be saved.";
    const beforeRouteHandler = (url: string) => {
      if (route.pathname !== url && callSave) {
        if (!confirm(confirmationMessage)) {
          route.events.emit("routeChangeError");
          throw `Route change to "${url}" was aborted.`;
        } else {
          clearContext();
        }
      }
    };
    route.events.on("routeChangeStart", beforeRouteHandler);

    return () => {
      route.events.off("routeChangeStart", beforeRouteHandler);
    };
  }, [callSave]);

  function startCall(id: string) {
    let startedCallResponse = LocalServices.setFullReportAPICallsStarted("get");
    if (!startedCallResponse.includes(id)) {
      startedCallResponse.push(id);
    }
    LocalServices.setFullReportAPICallsStarted("set", startedCallResponse);
  }

  function endCall(id: string) {
    let startedCallResponse = LocalServices.setFullReportAPICallsStarted("get");
    let doneCallResponse = LocalServices.setFullReportAPICallsDone("get");
    if (!doneCallResponse.includes(id)) {
      doneCallResponse.push(id);
    }
    LocalServices.setFullReportAPICallsDone("set", doneCallResponse);
    if (doneCallResponse?.length === startedCallResponse?.length) {
      notify.success(t("report.changesSaved"));
      setCallSave(false);
      setRefetch(true);
      LocalServices.setFullReportAPICallsStarted("remove");
      LocalServices.setFullReportAPICallsDone("remove");
    }
  }

  function clearContext() {
    LocalServices.setFullReportAPICallsStarted("remove");
    LocalServices.setFullReportAPICallsDone("remove");
    setCallSave(false);
    setRefetch(false);
    setNotesPages(1);
  }

  useEffect(() => {
    function paste(e: ClipboardEvent) {
      e.preventDefault();
      const plainText = e.clipboardData?.getData("text/plain");
      document.execCommand("insertHTML", false, plainText);
    }
    document.addEventListener("paste", paste);
    return () => document.removeEventListener("paste", paste);
  }, []);

  const {
    data: templateModuleData,
    isSuccess: isTemplateModuleDataSuccess,
    refetch: refetchTemplateModuleData,
    isFetching: isModuleConfigFetching,
  } = useQuery<unknown, unknown, TemplateModuleData>(
    [
      `/clients/${clientId}/report-templates/${reportTemplateId}/modules/${packageComponentId}`,
      { clientId: clientId, reportTemplateId, moduleId: packageComponentId },
    ],
    ReportServices.getWidgetSelectionModuleConfiguration,
    {
      refetchOnWindowFocus: false,
      enabled:
        !!clientId &&
        !!reportTemplateId &&
        !!packageComponentId &&
        !financialPackageId,
    }
  );

  const {
    data: reportModuleData,
    isSuccess: isReportModuleDataSuccess,
    refetch: refetchReportModuleData,
  } = useQuery<unknown, unknown, ReportModuleData>(
    [
      `/clients/${clientId}/report-module-config/${selectedPackage?.configuration?.id}/${financialPackage?.year}/${financialPackage?.month}`,
      {
        clientId: clientId,
        year: financialPackage?.year,
        month: financialPackage?.month,
        reportModuleConfigId: selectedPackage?.configuration?.id,
      },
    ],
    ReportServices.getMonthlyReportModulesConfiguration,
    {
      refetchOnWindowFocus: false,
      enabled:
        !!clientId &&
        !!financialPackage?.year &&
        !!financialPackage?.month &&
        !!selectedPackage?.configuration?.id &&
        packageComponentId === selectedPackage.id &&
        !selectedPackage?.isDfm &&
        !!financialPackageId,
    }
  );

  useEffect(() => {
    if (packageComponentId && packagesData?.items?.length > 0) {
      const findPackage = packagesData.items.find(
        (i) => i?.id === packageComponentId
      );
      if (findPackage?.id) {
        setSelectedPackage(findPackage);
      } else {
        let findChildPackage = null;
        packagesData?.items.some(
          (i: {
            id: string;
            children: {
              id: string;
            }[];
          }) => {
            const { children, haveChildren } =
              reportComponentItemResponseHelper(i);
            if (haveChildren) {
              const childItem = children.find(
                (c: { id: string }) => c.id === packageComponentId
              );
              if (childItem?.id) {
                findChildPackage = childItem;
                return true;
              }
            }
            return false;
          }
        );
        setSelectedPackage(findChildPackage);
      }
    }
  }, [packageComponentId, packagesData]);

  const value = useMemo(
    () => ({
      packagesData:
        packagesData?.items?.length > 0
          ? packagesData?.items.sort((a, b) =>
              a.displayOrder > b.displayOrder ? 1 : -1
            )
          : [],
      refetchPackages: refetchPackages,
      selectedPackage: selectedPackage,
      financialPackage: financialPackage,
      isReportPublished:
        financialPackage?.status === reportPublishStatus.published,
      isModuleDataSuccess: financialPackageId
        ? isReportModuleDataSuccess
        : isTemplateModuleDataSuccess,
      moduleData: financialPackageId ? reportModuleData : templateModuleData,
      refetchModuleData: financialPackageId
        ? refetchReportModuleData
        : refetchTemplateModuleData,
      isShowFullReport,
      setIsShowFullReport,
      callSave,
      setCallSave,
      refetch,
      setRefetch,
      isReport: !!financialPackageId,
      notesPages,
      setNotesPages,
      startCall,
      endCall,
      disableButtons,
      setDisableButtons,
      clearContext,
      reportTemplateId,
      packageComponentId,
      financialPackageId,
      setReportTemplateId,
      setPackageComponentId,
      setFinancialPackageId,
      isModuleConfigFetching,
    }),
    [
      packagesData,
      selectedPackage,
      isTemplateModuleDataSuccess,
      isReportModuleDataSuccess,
      templateModuleData,
      reportModuleData,
      financialPackage,
      isShowFullReport,
      callSave,
      notesPages,
      disableButtons,
      reportTemplateId,
      setReportTemplateId,
      packageComponentId,
      setPackageComponentId,
      financialPackageId,
      setFinancialPackageId,
      isModuleConfigFetching,
    ]
  );

  return (
    <ReportContext.Provider value={value}>{children}</ReportContext.Provider>
  );
};

export default ReportProvider;

ReportProvider.propTypes = {
  children: PropTypes.any,
};
